<template>
  <div class="container">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item.id">
          <h4>
            {{ item.meetingRoom.brand }}
            <van-tag
              type="success"
              v-if="
                (item.status == 0||item.status == 2) &&
                Date.parse(new Date()) > Date.parse(item.endTime)
              "
              >已完成</van-tag
            >
            <van-tag v-else-if="item.status == 2" type="success"
              >审核通过</van-tag
            >
            <van-tag v-else-if="item.status == 3" color="#969799"
              >审核不通过</van-tag
            >
            <van-tag v-else-if="item.status == 1" color="#969799"
              >已取消</van-tag
            >
            <van-tag v-else type="success">已预约</van-tag>
          </h4>
          <div>地址：{{ item.meetingRoom.address }}</div>
          <div>人数限定： {{ item.meetingRoom.peopleNumber }}</div>
          <div>主题：{{ item.title }}</div>

          <div>
            开始时间：
            {{ dateWithWeekStr(item.startTime,"MM月DD日HH时mm分")  }}
          </div>
          <div>
            结束时间：
            {{ dateWithWeekStr(item.endTime,"MM月DD日HH时mm分") }} 共计{{item.hours}}小时
          </div>
          <div v-if="item.auditMemo">审核信息：{{ item.auditMemo }}</div>
          <van-button
            v-if="
              item.status == 0 &&
              Date.parse(new Date()) + 300000 < Date.parse(item.startTime)
            "
            round
            type="info"
            native-type="submit"
            style="height: 30px; margin-left: 124px"
            @click="cancel(item)"
            >取消预约
          </van-button>
          <van-button
            v-if="
              item.status == 0 &&
              Date.parse(new Date()) > Date.parse(item.startTime) && Date.parse(new Date()) < Date.parse(item.endTime)
            "
            round
            type="info"
            native-type="submit"
            style="height: 30px; margin-left: 124px"
            @click="finish(item)"
            >提前结束
          </van-button>
          <van-button
            v-if="
              item.status == 0 &&
              Date.parse(new Date()) + 300000 > Date.parse(item.startTime) &&
              Date.parse(new Date()) < Date.parse(item.startTime)
            "
            round
            disabled
            type="info"
            native-type="submit"
            style="height: 30px; margin-left: 124px"
            @click="cancel(item)"
            >取消预约
          </van-button>
          <div
            v-if="
              item.status == 0 &&
              Date.parse(new Date()) + 300000 > Date.parse(item.startTime) &&
              Date.parse(new Date()) < Date.parse(item.startTime)
            "
            style="margin-left: 80px"
          >
            (会议还有5分钟开始,不能取消)
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import {
  getMeetingRoomReservedMyPageAPI,
  getMeetingRoomReservedCancleAPI,
  finishMeetingRoomReserved,
} from "../../api/reserveMeeting";
export default {
  data() {
    return {
      title: "我的预约",
      loading: false,
      refreshing: false,
      finished: false,
      page: {
        current: 1,
        pageSize: 10,
      },
      list: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
  },
  watch: {},
  methods: {
     async onLoad() {
      let d = await getMeetingRoomReservedMyPageAPI(this.page);
      if (!d.data.success && d.data.errorMessage) {
        Toast(d.data.errorMessage);
        return;
      }

      this.list.push(...d.data.data);
      this.loading = false;
      this.refreshing = false;
      if (this.page.current * this.page.pageSize < d.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    onRefresh() {
      this.list = [];
      this.page.current = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onClickLeft() {
      this.$router.back();
    },
    async cancel(item) {
      Dialog.confirm({
        message: "您确定要取消么？",
      })
        .then(async () => {
          const result = await getMeetingRoomReservedCancleAPI(item.id);
          console.log(result.data);
          if (result.data.success == true) {
            item.status=1;
            Dialog.alert({
              message: "已取消预约",
            });
          } else {
            Dialog.alert({
              message: result.data.errorMessage,
            });
          }
        })
        .catch(() => {
          console.log("catch");
        });
    },
    async finish(item){
      Dialog.confirm({
        message: "您确定现在提前结束么？",
      })
        .then(async () => {
          const result = await finishMeetingRoomReserved(item.id);
          console.log(result.data);
          if (result.data.success == true) {
            item.endTime=new Date().toLocaleString();
            Dialog.alert({
              message: "已提前结束会议室使用",
            });
          } else {
            Dialog.alert({
              message: result.data.errorMessage,
            });
          }
        })
        .catch(() => {
          console.log("catch");
        });
    },
    dateWithWeekStr(date, baseFormat = "MM-DD HH:mm") {
      let str = this.dayjs(date).format(baseFormat);
      switch (this.dayjs(date).day()) {
        case 0:
          str += "(周日)";
          break;
        case 1:
          str += "(周一)";
          break;
        case 2:
          str += "(周二)";
          break;
        case 3:
          str += "(周三)";
          break;
        case 4:
          str += "(周四)";
          break;
        case 5:
          str += "(周五)";
          break;
        case 6:
          str += "(周六)";
          break;
      }
      return str;
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 100px;
}
.loading {
  text-align: center;
}
</style>
