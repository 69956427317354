var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('van-nav-bar',{attrs:{"title":_vm.title,"left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item){return _c('van-cell',{key:item.id},[_c('h4',[_vm._v(" "+_vm._s(item.meetingRoom.brand)+" "),(
              (item.status == 0||item.status == 2) &&
              Date.parse(new Date()) > Date.parse(item.endTime)
            )?_c('van-tag',{attrs:{"type":"success"}},[_vm._v("已完成")]):(item.status == 2)?_c('van-tag',{attrs:{"type":"success"}},[_vm._v("审核通过")]):(item.status == 3)?_c('van-tag',{attrs:{"color":"#969799"}},[_vm._v("审核不通过")]):(item.status == 1)?_c('van-tag',{attrs:{"color":"#969799"}},[_vm._v("已取消")]):_c('van-tag',{attrs:{"type":"success"}},[_vm._v("已预约")])],1),_c('div',[_vm._v("地址："+_vm._s(item.meetingRoom.address))]),_c('div',[_vm._v("人数限定： "+_vm._s(item.meetingRoom.peopleNumber))]),_c('div',[_vm._v("主题："+_vm._s(item.title))]),_c('div',[_vm._v(" 开始时间： "+_vm._s(_vm.dateWithWeekStr(item.startTime,"MM月DD日HH时mm分"))+" ")]),_c('div',[_vm._v(" 结束时间： "+_vm._s(_vm.dateWithWeekStr(item.endTime,"MM月DD日HH时mm分"))+" 共计"+_vm._s(item.hours)+"小时 ")]),(item.auditMemo)?_c('div',[_vm._v("审核信息："+_vm._s(item.auditMemo))]):_vm._e(),(
            item.status == 0 &&
            Date.parse(new Date()) + 300000 < Date.parse(item.startTime)
          )?_c('van-button',{staticStyle:{"height":"30px","margin-left":"124px"},attrs:{"round":"","type":"info","native-type":"submit"},on:{"click":function($event){return _vm.cancel(item)}}},[_vm._v("取消预约 ")]):_vm._e(),(
            item.status == 0 &&
            Date.parse(new Date()) > Date.parse(item.startTime) && Date.parse(new Date()) < Date.parse(item.endTime)
          )?_c('van-button',{staticStyle:{"height":"30px","margin-left":"124px"},attrs:{"round":"","type":"info","native-type":"submit"},on:{"click":function($event){return _vm.finish(item)}}},[_vm._v("提前结束 ")]):_vm._e(),(
            item.status == 0 &&
            Date.parse(new Date()) + 300000 > Date.parse(item.startTime) &&
            Date.parse(new Date()) < Date.parse(item.startTime)
          )?_c('van-button',{staticStyle:{"height":"30px","margin-left":"124px"},attrs:{"round":"","disabled":"","type":"info","native-type":"submit"},on:{"click":function($event){return _vm.cancel(item)}}},[_vm._v("取消预约 ")]):_vm._e(),(
            item.status == 0 &&
            Date.parse(new Date()) + 300000 > Date.parse(item.startTime) &&
            Date.parse(new Date()) < Date.parse(item.startTime)
          )?_c('div',{staticStyle:{"margin-left":"80px"}},[_vm._v(" (会议还有5分钟开始,不能取消) ")]):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }